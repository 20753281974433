<template>
  <myGrid v-bind="{ setup }" />
</template>

<script>
import myGrid from "../components/myGrid.vue";
export default {
  components: {
    myGrid,
  },
  data: function () {
    return {
      setup: {
        header: "companies",
        searchbar: true,
        datastore: "Companies",
        dataColumns: [
          { name: "name", alignment: "left", data: "Name" },
          { name: "number", alignment: "left", data: "Number" },
          {
            name: "country",
            alignment: "left",
            data: "CompaniesToCountries.FlagURL",
            formatter: "image",
          },
          { name: "address", alignment: "left", data: "FirstAddress" },
          { name: "phone", alignment: "left", data: "Phone" },
        ],
      },
    };
  },
};
</script>
