<template>
  <myGrid v-bind="{ setup }" />
</template>

<script>
import myGrid from "../components/myGrid.vue";
export default {
  components: {
    myGrid,
  },
  data: function () {
    return {
      setup: {
        header: "contacts",
        searchbar: true,
        datastore: "Contacts",
        dataColumns: [
          { name: "name", alignment: "left", data: "FullName" },
          {
            name: "company",
            alignment: "left",
            data: "ContactsToCompanies.Name",
          },
          { name: "address", alignment: "left", data: "FirstAddress" },
          { name: "phone", alignment: "left", data: "Phone" },
        ],
      },
    };
  },
};
</script>
