export default {
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijven"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactpersoon"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "email_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer a.u.b. u email en wachtwoord in."])},
  "inclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusief"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openstaand"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoon"])},
  "purchase_invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkoopfacturen"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie"])},
  "sales_invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkoopfacturen"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken..."])},
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
  "welcome_miracle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom bij Miracle!"])}
}