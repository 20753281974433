<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header">Dashboard</div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-6">
                <vue3-chart-js ref="turnOverRef" v-bind="{ ...lineChart }" />
              </div>
              <div class="col-6">
                <vue3-chart-js ref="vatRef" v-bind="{ ...barChart }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import axios from "axios";
import { authComputed } from "../store/helpers.js";

export default {
  name: "App",
  components: {
    Vue3ChartJs,
  },
  computed: {
    ...authComputed,
    adminChange() {
      return this.adminId;
    },
  },
  created() {
    this.updateChart();
  },
  setup() {
    const turnOverRef = ref(null);
    const vatRef = ref(null);

    const lineChart = {
      type: "line",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        plugins: {},
      },
    };

    const barChart = {
      type: "bar",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        plugins: {},
      },
    };

    const updateChart = () => {
      axios.get("/api/charts/turnover").then((response) => {
        lineChart.data.labels = response.data.labels;
        lineChart.data.datasets = response.data.datasets;
        turnOverRef.value.update(250);
      });

      axios.get("/api/charts/vat").then((response) => {
        barChart.data.labels = response.data.labels;
        barChart.data.datasets = response.data.datasets;
        vatRef.value.update(250);
      });
    };

    return {
      lineChart,
      barChart,
      updateChart,
      turnOverRef,
      vatRef,
    };
  },
  watch: {
    adminChange() {
      this.updateChart();
    },
  },
};
</script>
