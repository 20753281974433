<template>
  <myGrid v-bind="{ setup }" />
</template>

<script>
import myGrid from "../components/myGrid.vue";
export default {
  components: {
    myGrid,
  },
  data: function () {
    return {
      setup: {
        header: "purchase_invoices",
        searchbar: true,
        datastore: "Purchases",
        dataColumns: [
          { name: "number", alignment: "left", data: "Number" },
          {
            name: "name",
            alignment: "left",
            data: "PurchasesToCompanies.Name",
          },
          { name: "description", alignment: "left", data: "Description" },
          { name: "reference", alignment: "left", data: "Reference" },
          { name: "date", alignment: "left", data: "Date", formatter: "date" },
          {
            name: "inclusive",
            alignment: "right",
            data: "AmountInclusive",
            formatter: "currency",
          },
        ],
      },
    };
  },
};
</script>
Reference
