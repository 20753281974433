<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header">{{ $t(setup.header) }}</div>
        <div class="card-body">
          <div
            v-if="setup.searchbar"
            class="search-wrapper panel-heading col-sm-12"
            @keyup="search"
          >
            <input
              type="text"
              v-model="searchValue"
              :placeholder="$t('search')"
            />
            <br />
          </div>
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th
                  v-for="(column, columnindex) in setup.dataColumns"
                  :key="columnindex"
                  scope="col"
                  :style="'text-align: ' + column.alignment"
                  @click="sortGeneral(column.data)"
                >
                  {{ $t(column.name) }}
                  <span v-if="sortField == column.data && sortDir == 'desc'"
                    ><i class="bi bi-chevron-down"></i
                  ></span>
                  <span v-else-if="sortField == column.data && sortDir == 'asc'"
                    ><i class="bi bi-chevron-up"></i></span
                  ><span v-else><i class="bi bi-chevron-expand"></i></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, rowindex) in rows"
                :key="rowindex"
                @dblclick="edit(row, $event)"
              >
                <td
                  v-for="(column, columnindex) in setup.dataColumns"
                  :key="columnindex"
                  scope="col"
                  :style="'text-align: ' + column.alignment"
                >
                  <img
                    v-if="column.formatter == 'image'"
                    :src="fillContent(row, column)"
                  />
                  <span v-else>{{ fillContent(row, column) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { authComputed } from "../store/helpers.js";
export default {
  props: {
    setup: Object,
  },
  computed: {
    ...authComputed,
    adminChange() {
      return this.adminId;
    },
  },
  data: function () {
    return {
      rows: [],
      searchValue: "",
      sortField: "",
      sortDir: "asc",
    };
  },
  methods: {
    sortGeneral(field) {
      if (this.sortField === field) {
        this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
      } else {
        this.sortField = field;
      }
      this.updateList();
    },
    fillContent(data, field) {
      const flattenObj = (obj, parent, res = {}) => {
        for (const key of Object.keys(obj)) {
          const propName = parent ? parent + "." + key : key;
          if (typeof obj[key] === "object") {
            flattenObj(obj[key], propName, res);
          } else {
            res[propName] = obj[key];
          }
        }
        return res;
      };

      switch (field.formatter) {
        case "image":
          return flattenObj(data)[field.data];

        case "date":
          var date = new Date(flattenObj(data)[field.data]);
          return new Intl.DateTimeFormat(navigator.language).format(date);

        case "currency":
          return new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: "EUR",
          }).format(flattenObj(data)[field.data]);

        default:
          return flattenObj(data)[field.data];
      }
    },
    updateList() {
      var column = [];
      this.setup.dataColumns.forEach((element) => {
        column.push(element.data);
      });
      const headers = { "Content-Type": "application/json" };
      axios
        .get("/api/data/" + this.setup.datastore, {
          headers,
          params: {
            $column: JSON.stringify(column),
            $search: this.searchValue,
            $sortField: this.sortField,
            $sortDir: this.sortDir,
          },
        })
        .then((response) => (this.rows = response.data[this.setup.datastore]));
    },
    search() {
      this.updateList();
    },
    edit(row) {
      console.log(row.__KEY);
    },
  },
  watch: {
    adminChange(admin) {
      this.updateList(admin);
    },
  },
  created() {
    this.updateList();
  },
};
</script>

<style scoped lang="scss"></style>
