import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    user: null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      localStorage.setItem("authenticated", true);
      state.user = userData;
    },
    CLEAR_USER_DATA(state) {
      localStorage.removeItem("authenticated");
      state.user = null;
      location.reload();
    },
  },
  actions: {
    login({ dispatch }, credentials) {
      return axios.post("/api/login", credentials).then(({ data }) => {
        if (data.success) {
          return dispatch("setup");
        }
      });
    },
    logout({ commit }) {
      return axios.post("/api/logoff").then(() => {
        commit("CLEAR_USER_DATA");
      });
    },
    setup({ commit }) {
      return axios.get("/api/setup").then(({ data }) => {
        commit("SET_USER_DATA", data);
      });
    },
    administration({ dispatch }, admin) {
      return axios.post("/api/admin", admin).then(({ data }) => {
        if (data.success) {
          return dispatch("setup");
        }
      });
    },
  },
  modules: {},
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    adminId(state) {
      if (state.user) {
        return state.user.administration.id;
      } else {
        return 0;
      }
    },
  },
});
