<template>
  <div class="container-fluid" style="padding: 0px">
    <div class="row no-gutter">
      <div class="col-md-6 d-none d-md-flex bg-image"></div>

      <div class="col-md-6 bg-light">
        <div class="login d-flex align-items-center py-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-xl-7 mx-auto">
                <h3 class="display-4">{{ $t("welcome_miracle") }}</h3>
                <div class="d-flex justify-content-center">
                  <img
                    src="../assets/images/Miracle_eu_full.png"
                    height="256"
                  />
                </div>
                <p class="text-muted mb-4">
                  {{ $t("email_and_password") }}
                </p>
                <form @submit.prevent="login">
                  <div class="mb-3">
                    <input
                      id="inputEmail"
                      type="email"
                      :placeholder="$t('email')"
                      required
                      autofocus
                      class="form-control rounded-pill border-0 shadow-sm px-4"
                      v-model="email"
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      id="inputPassword"
                      type="password"
                      :placeholder="$t('password')"
                      required
                      class="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                      v-model="password"
                    />
                  </div>
                  <div class="d-grid gap-2 mt-2">
                    <button
                      type="submit"
                      class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm"
                    >
                      {{ $t("sign_in") }}
                    </button>
                    <p class="text-danger">{{ error }}</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          console.log("AZ");
          this.error = err.response.data.error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-image: url("../assets/images/dunes.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
