import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "./assets/scss/custom.scss";
import "bootstrap/dist/js/bootstrap.js";
import i18n from "./i18n";

const app = createApp(App).use(i18n);

app.use(store);
app.use(router);
app.mount("#app");
