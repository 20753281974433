<template>
  <nav
    class="navbar sticky-top navbar-expand-lg navbar-light bg-light"
    v-if="loggedIn"
  >
    <div class="container-fluid">
      <a class="navbar-brand"
        ><img
          src="../src/assets/images/Miracle_eu_small.png"
          alt="Miracle"
          height="24"
          class="d-inline-block align-text-top"
        />
        Miracle</a
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navbar-nav mr-auto col-sm-9">
          <router-link to="/" class="nav-item nav-link">Dashboard</router-link>
          <router-link to="/companies" class="nav-item nav-link">{{
            $t("companies")
          }}</router-link>
          <router-link to="/contacts" class="nav-item nav-link">{{
            $t("contacts")
          }}</router-link>
          <router-link to="/salesinvoices" class="nav-item nav-link">{{
            $t("sales_invoices")
          }}</router-link>
          <router-link to="/purchaseinvoices" class="nav-item nav-link">{{
            $t("purchase_invoices")
          }}</router-link>
          <router-link to="/websites" class="nav-item nav-link"
            >Websites</router-link
          >
        </div>
        <div class="navbar-nav mr-auto col-sm-3 justify-content-end">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><i class="bi bi-building"></i>
            {{ this.$store.state.user.administration.name }}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
            <li
              v-for="(el, index) in this.$store.state.user.administration.list"
              :key="index"
            >
              <a class="dropdown-item" @click="changeAdmin(el)">{{
                el.Name
              }}</a>
            </li>
          </ul>

          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><i class="bi bi-person"></i> {{ this.$store.state.user.name }}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
            <li><a class="dropdown-item" href="#">Settings</a></li>
            <li><a class="dropdown-item" href="#">Change password</a></li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <a class="dropdown-item" href="#" @click="logout">Log off</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div class="container-fluid">
    <div class="row mt-3" v-if="loggedIn"></div>
    <router-view></router-view>
  </div>
</template>

<script>
import axios from "axios";
import { authComputed } from "./store/helpers.js";
export default {
  computed: {
    ...authComputed,
  },
  created() {
    this.$store.dispatch("setup");
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          if (this.$route.path != "/login") {
            this.$store.dispatch("logout");
          }
        }
        return Promise.reject(error);
      }
    );
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    changeAdmin(admin) {
      this.$store.dispatch("administration", admin);
    },
  },
};
</script>

<style lang="scss"></style>
