export default {
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactperson"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "email_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use your email and password to login."])},
  "inclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclusive"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "purchase_invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase invoices"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
  "sales_invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales invoices"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
  "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "welcome_miracle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Miracle!"])}
}