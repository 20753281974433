import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/my-Login.vue";
import Dashboard from "../views/my-Dashboard.vue";
import Companies from "../views/my-Companies.vue";
import Contacts from "../views/my-Contacts.vue";
import SalesInvoices from "../views/my-SalesInvoices.vue";
import PurchaseInvoices from "../views/my-PurchaseInvoices.vue";
import Websites from "../views/my-Websites.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/companies",
    name: "Companies",
    component: Companies,
    meta: { requiresAuth: true },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: { requiresAuth: true },
  },
  {
    path: "/salesinvoices",
    name: "SalesInvoices",
    component: SalesInvoices,
    meta: { requiresAuth: true },
  },
  {
    path: "/purchaseinvoices",
    name: "PurchaseInvoices",
    component: PurchaseInvoices,
    meta: { requiresAuth: true },
  },
  {
    path: "/websites",
    name: "Websites",
    component: Websites,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("authenticated");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
