<template>
  <myGrid v-bind="{ setup }" />
</template>

<script>
import myGrid from "../components/myGrid.vue";
export default {
  components: {
    myGrid,
  },
  data: function () {
    return {
      setup: {
        header: "Websites",
        searchbar: true,
        datastore: "Websites",
        dataColumns: [
          { name: "Description", alignment: "left", data: "Description" },
          { name: "URL", alignment: "left", data: "URL" },
          { name: "Username", alignment: "left", data: "Username" },
        ],
      },
    };
  },
};
</script>
